
















































































































































































































































































































import {
  BaseUser,
  DisableOrDeleteUserRequest,
  UserPermissions
} from "@sportango/backend";
import {
  CaptureEvent,
  LoadingMixin,
  PreventBubblingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { USER_TYPES } from "@/store/actions/users";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import MenuOrBottomSheet from "../Navigation/MenuOrBottomSheet.vue";
import UserAvatar from "./UserAvatar.vue";
import { httpsCallable } from "@firebase/functions";
import { Functions } from "@/firebase";
import { CALLABLE_FUNCTIONS } from "@/utils/callables";
import VerifiedIcon from "@/assets/icons/saxcons/email-tick-linear.svg";
import UnVerifiedIcon from "@/assets/icons/saxcons/email-time-linear.svg";
import ActiveIcon from "@/assets/icons/saxcons/user-tick-linear.svg";
import InActiveIcon from "@/assets/icons/saxcons/user-minus-linear.svg";
import MoreIcon from "@/assets/icons/saxcons/more-linear.svg";
import EditIcon from "@/assets/icons/saxcons/edit-linear.svg";
import SubscriptionIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import DeleteIcon from "@/assets/icons/saxcons/trash-linear.svg";
import dayjs from "dayjs";
import { SportangoChip } from "@/components/common/overrides";

@Component({
  name: "user-item",
  components: {
    SportangoChip,
    DeleteIcon,
    MoreIcon,
    UnVerifiedIcon,
    InActiveIcon,
    MenuOrBottomSheet,
    UserAvatar,
    VerifiedIcon,
    ActiveIcon,
    EditIcon,
    SubscriptionIcon
  }
})
export default class UserItem extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  PreventBubblingMixin,
  CaptureEvent
) {
  @Prop({ required: true })
  user!: BaseUser;

  @Prop({ required: false, default: false })
  isPlayer!: boolean;

  @Prop({ required: false, default: [] })
  secondaryProfiles!: BaseUser[];

  permissions: Array<USER_TYPES> = [];

  permissionNames: Record<keyof UserPermissions, string> = {
    hasCoachAccess: "Coach",
    hasPlayerAccess: "Player",
    hasAdminAccess: "Admin"
  };

  loadingStatuses: Record<
    keyof UserPermissions | "disableUser" | "deleteUser" | "enableUser",
    boolean
  > = {
    hasCoachAccess: false,
    hasPlayerAccess: false,
    hasAdminAccess: false,
    disableUser: false,
    deleteUser: false,
    enableUser: false
  };

  async updatePermission(type: USER_TYPES) {
    const newPermissions = {
      ...this.user.permissions
    };
    switch (type) {
      case "PLAYER":
        this.loadingStatuses.hasPlayerAccess = true;
        newPermissions.hasPlayerAccess = !newPermissions.hasPlayerAccess;
        break;
      case "COACH":
        this.loadingStatuses.hasCoachAccess = true;
        newPermissions.hasCoachAccess = !newPermissions.hasCoachAccess;
        break;
      case "ADMIN":
        this.loadingStatuses.hasAdminAccess = true;
        newPermissions.hasAdminAccess = !newPermissions.hasAdminAccess;
        break;
    }
    await this.$store.dispatch("updateUser", {
      uid: this.user.uid,
      newData: {
        permissions: newPermissions
      }
    });
    switch (type) {
      case "PLAYER":
        this.loadingStatuses.hasPlayerAccess = false;
        break;
      case "COACH":
        this.loadingStatuses.hasCoachAccess = false;
        break;
      case "ADMIN":
        this.loadingStatuses.hasAdminAccess = false;
        break;
    }
  }

  get userSubscriptionName(): string {
    if (this.user.additionalInfo?.membershipId) {
      const userSubscription = this.$store.getters.subscriptions.find(
        (s) => s.id === this.user.additionalInfo?.membershipId
      );
      if (userSubscription) {
        const membershipName = this.$store.getters.memberships.find((m) =>
          userSubscription.products.find((p) => p.productId === m.id)
        )?.name;
        if (membershipName) {
          return membershipName;
        }
      }
      return this.isDesktop ? "Yes" : "Has membership";
    } else {
      return this.isDesktop ? "No" : "No membership";
    }
  }

  get contentHeight(): number {
    if (this.isDesktop) {
      if (this.secondaryProfiles.length > 0) {
        return 102;
      }
      return 64;
    } else {
      if (this.secondaryProfiles.length > 0) {
        return 132;
      }
      return 96;
    }
  }

  get menuWidth(): number | string {
    if (this.isDesktop) {
      return 200;
    } else {
      return innerWidth;
    }
  }

  get registeredDate(): string {
    if (this.user.registeredDate) {
      return dayjs(this.user.registeredDate).format("MM/DD/YYYY");
    }
    return "--/--/----";
  }

  get registeredDateDuration(): string {
    const diff = dayjs(new Date()).diff(this.user.registeredDate, "minutes");
    const diffInMonths = dayjs(new Date()).diff(
      this.user.registeredDate,
      "months"
    );
    if (diff < 60) {
      return `${diff} minutes ago`;
    } else if (diff >= 60 && diff < 1440) {
      const diffInHours = dayjs(new Date()).diff(
        this.user.registeredDate,
        "hours"
      );
      return `${diffInHours} hour${diffInHours > 1 ? "" : "s"} ago`;
    } else if (diff >= 1440 && diffInMonths < 1) {
      const diffInDays = dayjs(new Date()).diff(
        this.user.registeredDate,
        "days"
      );
      return `${diffInDays} day${diffInDays <= 1 ? "" : "s"} ago`;
    } else if (diffInMonths >= 1) {
      return `${diffInMonths} month${diffInMonths <= 1 ? "" : "s"} ago`;
    }
    return "";
  }

  get hasMembership() {
    if (this.user.additionalInfo?.membershipId) {
      return true;
    }
    return false;
  }

  @Watch("user.permissions")
  loadPermissions() {
    const newPermissions: USER_TYPES[] = [];
    if (this.user.permissions.hasCoachAccess) {
      newPermissions.push("COACH");
    }
    if (this.user.permissions.hasPlayerAccess) {
      newPermissions.push("PLAYER");
    }
    if (this.user.permissions.hasAdminAccess) {
      newPermissions.push("ADMIN");
    }
    this.permissions = Array.from(new Set(newPermissions));
  }

  mounted() {
    this.loadPermissions();
  }

  openProfile(e: PointerEvent, user: BaseUser = this.user) {
    this.preventBubbling(e);
    if (
      !(
        (e.target as HTMLElement).tagName === "SPAN" &&
        (e.target as HTMLElement).className === "v-btn__content"
      )
    ) {
      if (this.isPlayer) {
        this.$router.push(`players/profile/${user.uid}`);
      } else {
        this.$router.push(`users/profile/${user.uid}`);
      }
    }
  }

  openMembership(e: PointerEvent) {
    this.preventBubbling(e);
    if (
      !(
        (e.target as HTMLElement).tagName === "SPAN" &&
        (e.target as HTMLElement).className === "v-btn__content"
      )
    ) {
      if (this.isPlayer) {
        this.$router.push(`players/membership/${this.user.uid}`);
      } else {
        this.$router.push(`users/membership/${this.user.uid}`);
      }
    }
  }
  async disableUser(event: Event) {
    this.preventBubbling(event);
    if (!this.user.disabled) {
      this.loadingStatuses.disableUser = true;
      const disableUserFunction = httpsCallable<DisableOrDeleteUserRequest>(
        Functions,
        CALLABLE_FUNCTIONS.disableUser
      );
      await disableUserFunction({
        uid: this.user.uid,
        email: this.user.email
      });
      this.user.disabled = true;
      this.$store.commit(
        "users",
        this.$store.getters.users.map((u) => {
          if (u.uid === this.user.uid) {
            u.disabled = true;
          }
          return u;
        })
      );
      this.loadingStatuses.disableUser = false;
    }
  }
  async enableUser(event: Event) {
    this.preventBubbling(event);
    if (this.user.disabled) {
      this.loadingStatuses.disableUser = true;
      const enableUserUserFunction = httpsCallable<DisableOrDeleteUserRequest>(
        Functions,
        CALLABLE_FUNCTIONS.enableUser
      );
      await enableUserUserFunction({
        uid: this.user.uid,
        email: this.user.email
      });
      this.user.disabled = false;
      this.$store.commit(
        "users",
        this.$store.getters.users.map((u) => {
          if (u.uid === this.user.uid) {
            u.disabled = false;
          }
          return u;
        })
      );
      this.loadingStatuses.disableUser = false;
    }
  }

  async deleteUser(event: Event) {
    this.preventBubbling(event);
    if (!this.user.emailVerified) {
      this.loadingStatuses.deleteUser = true;
      const deleteUserFunction = httpsCallable<DisableOrDeleteUserRequest>(
        Functions,
        CALLABLE_FUNCTIONS.deleteUser
      );
      await deleteUserFunction({
        uid: this.user.uid
      });
      this.$store.commit(
        "users",
        this.$store.getters.users.filter((u) => u.uid !== this.user.uid)
      );
      this.loadingStatuses.deleteUser = false;
    }
  }
}
