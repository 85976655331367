/* eslint-disable @typescript-eslint/ban-ts-comment */
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";

@Component
export class RouteWatcherMixin extends Vue {
  public propertiesToWatch: Array<string>;
  private valueMap: Record<
    string,
    {
      before?: any;
      after?: any;
    }
  >;
  constructor() {
    super();
    this.propertiesToWatch = [];
    this.valueMap = {};
  }
  startWatch() {
    this.propertiesToWatch.forEach((prop) => {
      this.valueMap[prop] = {
        // @ts-ignore
        before: this[prop]
      };
      this.$watch(prop, function (this, n, o) {
        // @ts-ignore
        this.updatedRouteByPropName(prop, n, o);
      });
    });
  }
  private updatedRouteByPropName(
    this: this,
    propName: string,
    newPropValue: any,
    oldPropValue: any
  ) {
    const routeValue = this.getConvertedQueryValue(
      this.$route.query[propName],
      newPropValue
    );
    if (newPropValue !== routeValue) {
      // prop value changed and route unchanged
      if (routeValue === undefined && newPropValue !== false) {
        // Push to the router queue
        if (Object.keys(this.$route.query).length === 0) {
          this.$router.push(
            `${this.$route.fullPath}?${propName}=${newPropValue}`
          );
        } else {
          this.$router.push(
            `${this.$route.fullPath}&${propName}=${newPropValue}`
          );
        }
      } else if (newPropValue === this.valueMap[propName].before) {
        // Go back if newValue is the same before old value
        if (routeValue !== undefined) {
          this.$router.back();
        }
      } else if (newPropValue === this.valueMap[propName].after) {
        // Go Forward
        this.$router.forward();
      }
    }
    this.valueMap[propName].before = oldPropValue;
  }
  @Watch("$route.query")
  private watchRouteQuery(
    newQuery: Dictionary<string | (string | null)[]>,
    oldQuery: Dictionary<string | (string | null)[]>
  ) {
    this.propertiesToWatch
      .filter((p) => {
        return newQuery[p] !== oldQuery[p];
      })
      .forEach((p) => {
        // @ts-ignore
        const thisValue = this[p];
        const queryValue = this.getConvertedQueryValue(
          this.$route.query[p],
          thisValue
        );
        if (thisValue !== queryValue) {
          if (queryValue === undefined) {
            if (typeof thisValue === "boolean" && thisValue !== false) {
              // @ts-ignore
              this[p] = false;
            }
          } else {
            // @ts-ignore
            this[p] = queryValue;
          }
        }
      });
  }

  private getConvertedQueryValue(
    queryValue: string | undefined | (string | null)[],
    thisValue: any
  ): boolean | string | undefined {
    if (typeof thisValue === "boolean") {
      if (queryValue === undefined || queryValue === null) {
        return undefined;
      } else if (typeof queryValue === "string") {
        return JSON.parse(queryValue);
      }
    }
  }

  mounted() {
    this.propertiesToWatch.forEach((p) => {
      if (this.$route.query[p]) {
        this.$router.replace(
          this.$route.fullPath.replace(`${p}=${this.$route.query[p]}`, "")
        );
      }
    });
    this.startWatch();
  }
}
