






































































































































































import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import UserItem from "@/components/users/UserItem.vue";
import { BaseUser, UserInvite } from "@sportango/backend";
import InviteUser from "@/assets/icons/saxcons/sms-tracking-broken.svg";
import { RouteWatcherMixin } from "@/mixins/RouteWatcher";
import InviteUserForm from "@/views/users/InviteUser.vue";
import { WatchLoading } from "@/decorators/Loading";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import Fuse from "fuse.js";
import ArrowDownIcon from "@/assets/icons/saxcons/arrow-down-linear.svg";
import CopyIcon from "@/assets/icons/saxcons/copy-linear.svg";
import MenuOrBottomSheet from "@/components/Navigation/MenuOrBottomSheet.vue";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import {
  SportangoSelect,
  SportangoTextField
} from "@/components/Inputs/overrides";
import Divider from "@/components/common/Divider.vue";
import UserInviteItem from "@/components/users/UserInviteItem.vue";
import { Header } from "@/types/Table";

@Component({
  name: "users-directory",
  components: {
    SportangoSelect,
    UserInviteItem,
    Divider,
    SportangoTextField,
    AppDialog,
    MenuOrBottomSheet,
    UserItem,
    InviteUser,
    InviteUserForm,
    LottieAnimation,
    ArrowDownIcon,
    CopyIcon
  }
})
export default class UsersDirectory extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  RouteWatcherMixin,
  CurrentUserMixin
) {
  isCreateActive = false;
  searchString = "";
  selectedUserType: "admins" | "players" | "coaches" | null = null;
  userStatusType: "Active" | "Inactive" | "All" = "Active";
  sortBy: "name" | "membership" | "join-date" = "join-date";
  itemsPerPage = 10;
  readonly allowedPageSizes = [10, 20, 50, -1];
  readonly pageSizesItems;

  constructor() {
    super();
    this.propertiesToWatch = ["isCreateActive"];
    this.pageSizesItems = this.allowedPageSizes.map((i) => {
      return {
        text: i === -1 ? "All" : i,
        value: i
      };
    });
  }
  private get users(): Array<BaseUser> {
    if (this.showPlayers) {
      return this.$store.getters.coachPlayers;
    }
    return this.$store.getters.users.filter(
      (u) => u.uid !== this.currentUser?.uid
    );
  }

  get filteredPlayers(): Array<BaseUser> {
    let results: BaseUser[] = [];
    let allUsers = [...this.users];
    switch (this.selectedUserType) {
      case "admins":
        allUsers = [...this.users.filter((u) => u.permissions.hasAdminAccess)];
        break;
      case "players":
        allUsers = [...this.users.filter((u) => u.permissions.hasPlayerAccess)];
        break;
      case "coaches":
        allUsers = [...this.users.filter((u) => u.permissions.hasCoachAccess)];
        break;
    }
    switch (this.userStatusType) {
      case "Active":
        allUsers = [...allUsers.filter((u) => !u.disabled)];
        break;
      case "Inactive":
        allUsers = [...allUsers.filter((u) => u.disabled)];
        break;
    }
    if (this.searchString.length === 0) {
      results = allUsers;
    } else {
      results = allUsers.filter((u) => {
        let res = false;
        if (u.displayName) {
          res = this.valueMatchesSearchString(u.displayName);
        }
        // Try to match email if not found already
        if (!res && u.email) {
          res = this.valueMatchesSearchString(u.email);
        }
        return res;
      });
    }
    const parentsOfMatch: BaseUser[] = [];
    results.forEach((r) => {
      if (r.isVirtual) {
        const parentId = r.uid.split("-")[0];
        if (
          parentsOfMatch.find((p) => p.uid === parentId) ||
          results.find((r) => r.uid === parentId)
        ) {
          return;
        }
        const foundParent = allUsers.find((u) => parentId === u.uid);
        if (foundParent) {
          parentsOfMatch.push(foundParent);
        }
      }
    });
    return [...results, ...parentsOfMatch];
  }

  get secondaryProfileMap(): Record<string, BaseUser[]> {
    const response: Record<string, BaseUser[]> = {};
    this.filteredPlayers
      .filter((p) => !p.isVirtual)
      .forEach((u) => {
        response[u.uid] = this.filteredPlayers.filter(
          (p) => p.uid.startsWith(u.uid) && p.uid !== u.uid
        );
      });
    return response;
  }

  get userHeaders(): Array<Header<BaseUser>> {
    if (this.isDesktop) {
      return [
        {
          text: "User",
          sortable: true,
          value: "displayName",
          align: "start",
          divider: true,
          sort: (a: string, b: string) => {
            if (a && b) {
              return a.localeCompare(b);
            }
            return 0;
          }
        },
        {
          text: "Membership",
          sortable: false,
          value: "additionalInfo",
          align: "center",
          divider: true,
          width: 200
        },
        {
          text: "Joined",
          sortable: true,
          value: "registeredDate",
          align: "center",
          divider: true,
          width: 200,
          sort: (a = 0, b = 0) => {
            return a - b;
          }
        },
        {
          text: "Roles",
          sortable: false,
          value: "permissions",
          align: "center",
          divider: true,
          width: 200
        },
        {
          text: "Options",
          sortable: false,
          value: "uid",
          align: "start",
          divider: true,
          width: 50
        }
      ];
    }
    return [];
  }

  get invites(): Array<UserInvite> {
    return this.$store.getters.invites.filter(
      (iV) => !this.users.find((u) => u.email === iV.email)
    );
  }

  get filteredInvites(): Array<UserInvite> {
    if (this.searchString.length === 0) {
      return this.invites;
    }
    const fuse = new Fuse<UserInvite>(this.invites, {
      keys: ["email"],
      minMatchCharLength: 2
    });
    return fuse.search(this.searchString).map((r) => r.item);
  }

  get showPlayers(): boolean {
    return this.$route.fullPath.includes("players");
  }

  get showInvites(): boolean {
    return this.$store.getters.featureFlags["invite-list"];
  }

  get inviteLink(): string {
    return `${window.location.origin}/register/players/?coachId=${this.currentUser?.uid}`;
  }
  get adminCount(): number {
    return this.users.filter((u) => !u.disabled && u.permissions.hasAdminAccess)
      .length;
  }
  get playersCount(): number {
    return this.users.filter(
      (u) => !u.disabled && u.permissions.hasPlayerAccess
    ).length;
  }
  get coachCount(): number {
    return this.users.filter((u) => !u.disabled && u.permissions.hasCoachAccess)
      .length;
  }
  get menuPosition(): number {
    return window.innerHeight - 152 - (8 + this.allowedPageSizes.length * 36);
  }
  valueMatchesSearchString(valueToMatch: string): boolean {
    return (
      valueToMatch
        .trim()
        .toLowerCase()
        .startsWith(this.searchString.toLowerCase().trim()) ||
      valueToMatch
        .trim()
        .toLowerCase()
        .endsWith(this.searchString.toLowerCase().trim()) ||
      valueToMatch
        .trim()
        .toLowerCase()
        .includes(this.searchString.toLowerCase().trim())
    );
  }

  @WatchLoading()
  async mounted(): Promise<void> {
    await this.$store.dispatch("getAllMemberships");
    await this.$store.dispatch("getAllSubscriptions");
    if (this.showPlayers) {
      this.$store.commit("coachPlayers", []);
      await this.$store.dispatch("getCurrentCoachPlayers");
    } else {
      this.$store.commit("users", []);
      await this.$store.dispatch("getUsers");
    }
    if (this.showInvites) {
      await this.$store.dispatch("getAllInvites");
    }
  }

  async userInvited() {
    if (this.showInvites) {
      await this.$store.dispatch("getAllInvites");
    }
    this.isCreateActive = false;
  }
  closeDialog() {
    if (this.showPlayers) {
      this.$router.replace("/players");
    } else {
      this.$router.replace("/users");
    }
  }
}
