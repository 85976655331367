























































import { InviteUserRequest } from "@sportango/backend";
import { CALLABLE_FUNCTIONS } from "@/utils/callables";
import { EMAIL_VALIDATION_RULES } from "@/utils/validation";
import { Functions } from "@/firebase";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { httpsCallable } from "@firebase/functions";
import Component, { mixins } from "vue-class-component";
import { WatchLoading } from "@/decorators/Loading";
import { SportangoTextField } from "@/components/Inputs/overrides";
import PwaConfig from "@sportango/site-configuration/out/pwa";

@Component({
  name: "invite-user-form",
  components: {
    SportangoTextField
  }
})
export default class InviteUserForm extends mixins(
  LoadingMixin,
  CurrentUserMixin
) {
  userInfo: InviteUserRequest;
  emailRules = EMAIL_VALIDATION_RULES;
  isValid = false;
  errorMessage: string | null = null;
  constructor() {
    super();
    this.userInfo = {
      email: "",
      allowAdminAccess: false,
      allowCoachAccess: false,
      allowPlayerAccess: true,
      invitedBy: this.currentUser?.uid || ""
    };
  }

  get showAccessControls(): boolean {
    return this.currentUser?.permissions.hasAdminAccess || false;
  }
  @WatchLoading()
  async save() {
    const inviteUserFunction = httpsCallable<
      InviteUserRequest,
      {
        message: string;
        isSuccess?: boolean;
      }
    >(Functions, CALLABLE_FUNCTIONS.inviteUser);
    if (
      this.$store.getters.featureFlags["coach-merchants"] &&
      this.currentUser?.permissions.hasCoachAccess
    ) {
      this.userInfo.coachId = this.currentUser.uid;
      this.userInfo.domain = window.location.origin;
    } else {
      this.userInfo.clubName = PwaConfig.name;
      this.userInfo.clubLink = window.location.origin;
    }
    const response = (await inviteUserFunction(this.userInfo)).data;
    if (response.isSuccess) {
      this.errorMessage = null;
      this.$emit("added");
    } else {
      this.errorMessage = response.message;
    }
  }
}
