



























































import { UserInvite } from "@sportango/backend";
import { LoadingMixin, PreventBubblingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MenuOrBottomSheet from "../Navigation/MenuOrBottomSheet.vue";
import DeleteIcon from "@/assets/icons/saxcons/trash-linear.svg";
import ResendIcon from "@/assets/icons/saxcons/email-resend-linear.svg";
import { WatchLoading } from "@/decorators/Loading";

@Component({
  name: "user-invite-item",
  components: {
    ResendIcon,
    DeleteIcon,
    MenuOrBottomSheet
  }
})
export default class UserInviteItem extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  PreventBubblingMixin
) {
  @Prop({ required: true })
  private user!: UserInvite;
  isBeingDeleted = false;
  isResendingInvite = false;

  @WatchLoading()
  async resendEmail() {
    this.isResendingInvite = true;
    await this.$store.dispatch("resendInvite", this.user.id);
    this.isResendingInvite = false;
  }
  @WatchLoading()
  async deleteInvite() {
    this.isBeingDeleted = true;
    await this.$store.dispatch("deleteInvite", this.user.id);
    this.isBeingDeleted = false;
  }
}
